<template>
  <div id="newsStoryContent">
    <p id="Text1">
      We are delighted to announce that the trading system in LEGO Universe will be getting an update!  The new update features a safer method of trading, ensuring that you know <b>exactly</b> what you are getting!  The good news doesn’t stop there!  The new update will feature over 35 new preset trade values, allowing you to trade up to <b>a million coins</b>!  The trade system update is still undergoing the final stages of development, but will hit the Nexus soon!  Get in the know now and be ready to start some serious trading with complete peace of mind!
    </p>
    <p>
      To trade in LEGO Universe, all you need to do is walk up to the minifigure you’d like to trade with.  Press CTRL and select “Trade” from the menu on the right side of the screen.  A window will open up with two sides.  The left side is your window, where you will place the items you want to offer to the other player.  The right side is the other minifigure’s window, where they will place the items they want to offer to you!
    </p>
    <p>
      <!-- picB62F9F2D5C06482FCAE826661E227481.png -->
      <img src="@/assets/news-network/trade-with-peace-1.png" style="height: 362px;" class="rounded">
    </p>
    <p>
      All you need to do is drag and drop an item from your Backpack into your side of the trade window.  If you would like to add coins to the trade, you can select from over 35 preset trade values too!  Once you are happy with what you would like to send to the other player, confirm this by clicking the “Accept Offer” button.  Your side of the window will now turn green, indicating to the other player that you have confirmed your side of the trade.  When the minifigure you are trading with also confirms their side of the trade, both windows will turn green.
    </p>
    <p>
      <!-- pic9CD525AEED089A9241C15EF34A380E54.png -->
      <img src="@/assets/news-network/trade-with-peace-2.png" style="height: 390px;" class="rounded">
    </p>
    <p>
      At this point, both minifigures will have a chance to do a final review of the trade!  You can either choose to change your trade offer, or accept the trade.  <b>If anyone opts to change the trade offer, their green window will go back to black, and the other party will be notified that the person they are trading with wants to change the items they are offering.</b>  This way, no one can swap out items at the last minute without you knowing!
    </p>
    <p>
      <!-- pic1000D2894CD311DCDE910DB788D5951F.png -->
      <img src="@/assets/news-network/trade-with-peace-3.png" style="height: 490px;" class="rounded">
    </p>
    <p>
      After both sides of the trade window have been verified and turned green, both players will again have to press “Accept Offer” which confirms that you are agreeing to this trade.  You will also be prompted to make sure to double check the items that the player is offering, to make sure that the trade is fair before you accept.
    </p>
    <p>
      <!-- pic4239B4E7859C217B6A5CAFF20C870F45.png -->
      <img src="@/assets/news-network/trade-with-peace-4.png" style="height: 427px;" class="rounded">
    </p>
    <p>
      If the minifigure you are trading with offers nothing in return (which is great at times, gifts are cool!), there will be an extra message that will make sure you know that the other minifig is offering you nothing in return.  These extra measures are all done to make sure that everyone gets a fair trade and always knows exactly what you are getting!
    </p>
    <p>
      <!-- picAAE0720682DCCBB778717AF0E453C546.png -->
      <img src="@/assets/news-network/trade-with-peace-5.png" style="height: 362px;" class="rounded">
    </p>
    <p>
      We hope all of you look forward to the new trading update, which features safety in trading and more customization in the preset trade values!  Stay tuned for more details on when to expect this update in the Nexus!
    </p>
    <p>
      Discuss this article on the <router-link to="/messageboards/4170266">message boards</router-link>!</p>
  </div>
</template>
